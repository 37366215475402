import React, { useState } from 'react';
import { FreeMode, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Buttons } from './components/Buttons';
import { Description } from './components/Description';
import { Eyebrows } from './components/Eyebrows';
import { SquareTab } from './components/SquareTab';
import { Button } from '../../atoms/Button';
import { ETitlePartsSize, TitlePart } from '../../atoms/TitlePart';
import { Container, EContainerVariant } from '../../sections/Container';
import {
  StatsV2,
  EStatsAlign,
  EStatsColor,
  EStatsNumberPosition,
  ESizeSettings,
} from '../StatsV2';

import type { ImageProps } from 'next/image';

type TStat = { title?: string; subTitle?: string; description?: string };

type TInfoCard = {
  tabTitle: string;
  tabIcon?: ImageProps | null;
  topButton?: React.ComponentProps<typeof Button>;
  label?: string;
  eyebrows?: string[];
  title: React.JSX.Element | string | null;
  description?: React.JSX.Element | string | null;
  buttons?: React.ComponentProps<typeof Button>[];
  stats: TStat[];
};

type TInfoCardProps = {
  cards: TInfoCard[];
  topBackgroundColor?: string;
};

export const InfoCard: React.FC<TInfoCardProps> = ({
  cards = [],
  topBackgroundColor,
}) => {
  const [selectedCard, setSelectedCard] = useState(0);

  const onClickTab = (index: number): void => {
    setSelectedCard(index);
  };

  const selected = cards[selectedCard];

  if (!cards.length) {
    return null;
  }

  return (
    <div>
      {cards.length > 1 ? (
        <div
          className='overflow-hidden bg-accent-500'
          style={
            topBackgroundColor
              ? { backgroundColor: topBackgroundColor }
              : undefined
          }
        >
          <Container variant={EContainerVariant.Main}>
            <Swiper
              className='relative mx-[initial] flex overflow-visible pb-8'
              spaceBetween={10}
              slidesPerView='auto'
              wrapperClass='flex'
              loop={false}
              pagination={false}
              simulateTouch={true}
              followFinger={true}
              freeMode={true}
              grabCursor={true}
              modules={[Mousewheel, FreeMode]}
              mousewheel={{ releaseOnEdges: true, forceToAxis: true }}
            >
              {cards.map((card, index) => {
                return (
                  <SwiperSlide
                    className='me-2.5 w-auto cursor-grab select-none pb-4'
                    key={index}
                  >
                    <SquareTab
                      icon={card.tabIcon}
                      title={card.tabTitle}
                      active={selectedCard === index}
                      onClick={() => onClickTab(index)}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Container>
        </div>
      ) : null}

      <Container variant={EContainerVariant.Main}>
        <div className='mt-8 flex flex-col gap-8 rounded-3xl border-2 border-surface-200 p-[30px] lg:gap-14'>
          <div className='flex flex-col justify-between gap-x-4 gap-y-8 lg:flex-row'>
            <div className='lg:order-2'>
              <div className='flex flex-row flex-wrap items-center justify-between gap-8 lg:justify-end'>
                {selected.label ? (
                  <div className='rounded-lg bg-surface-150 px-2 py-1 text-[13px] font-bold leading-normal tracking-[0.65px] text-[rgba(113,116,122,1)] lg:order-2'>
                    {selected.label}
                  </div>
                ) : null}

                {selected.topButton ? (
                  <Button {...selected.topButton}>
                    {selected.topButton.children}
                  </Button>
                ) : null}
              </div>
            </div>

            <div className='flex flex-col gap-4 lg:max-w-[400px] xl:max-w-[548px] 2xl:max-w-[784px]'>
              <Eyebrows eyebrows={selected.eyebrows} />
              <TitlePart
                size={ETitlePartsSize.Xl}
                className='prose-a:text-interface-1000 prose-a:underline'
              >
                {selected.title}
              </TitlePart>
              <Description description={selected.description} />
              <Buttons buttons={selected.buttons} />
            </div>
          </div>

          <div>
            {selected.stats.length ? (
              <StatsV2
                size={ESizeSettings.Fixed}
                align={EStatsAlign.Left}
                position={EStatsNumberPosition.NumbersUnder}
                color={EStatsColor.Black}
                stats={selected.stats}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
};
