import {
  InfoCard,
  isRichTextContentEmpty,
  RichText,
  toImageProps,
} from '@front/shared/ds';
import { useRouter } from 'next/router';
import React, { useMemo, ComponentProps } from 'react';

import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

import type { Section } from '@shared/master-types';

type TInfoCard = ComponentProps<typeof InfoCard>['cards'][number];

export type TInfoCardConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'info-card' }
>;

const InfoCardConnected: React.FC<TInfoCardConnectedProps> = ({
  cards = [],
  topBackgroundColor,
}) => {
  const appDispatch = useAppDispatch();
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');

  const preparedCards = useMemo(() => {
    const data: TInfoCard[] = [];

    cards.forEach(card => {
      const preparedData: TInfoCard = {
        tabTitle: card.tabTitle || '',
        tabIcon: toImageProps(card.tabIcon),
        topButton: card.topButton
          ? (toCardButtons(card.topButton || [], currentLocale, appDispatch) ||
              [])[0]
          : undefined,
        label: card.label,
        eyebrows: (card.eyebrows || []).map(eyebrow => eyebrow.text),
        title:
          card.title && !isRichTextContentEmpty(card.title) ? (
            <RichText content={card.title} />
          ) : null,
        description:
          card.description && !isRichTextContentEmpty(card.description) ? (
            <RichText content={card.description} />
          ) : null,
        buttons: toCardButtons(card.buttons || [], currentLocale, appDispatch),
        stats: card.stats || [],
      };

      data.push(preparedData);
    });

    return data;
  }, [appDispatch, cards, currentLocale]);

  return (
    <InfoCard cards={preparedCards} topBackgroundColor={topBackgroundColor} />
  );
};

export default InfoCardConnected;
